<!--
 This component is called when creating a new product inside collection
 as well as when viewing product information
-->

<template>
  <app-dialog
    v-model="showProductModal"
    :transition="false"
    :value.sync="showProductModal"
    content-class="v-dialog__form sourcery__dialog"
    origin="right"
    overlay-color="black"
    overlay-opacity="0.75"
    @keydown.esc="showProductModal = false">
    <v-card :style="{ 'opacity': productModalInvisible ? 0 : 1 }">
      <v-card-title>
        <div class="d-flex align-center">
          <span v-if="isEditModalVariant">Update Product</span>
          <span v-else-if="isAddModalVariant">Create or follow an existing Product</span>
          <span
            v-else-if="isReadOnlyModalVariant || isCompareModalVariant || isSpecSheetModalVariant">
            Product Details
          </span>
          <span v-else-if="isSaveAsNewVariant">Save as new product</span>
          <span v-else-if="isUpdateReferencedProduct">Update Referenced Product</span>
        </div>
        <div class="d-flex align-center gap-3">
          <!-- product share + compare toolbar -->
          <ActionsWrapper
            v-if="$vuetify.breakpoint.mobile"
            :show-verified-compare="useComparisonTool && !(isEditModalVariant || isCompareModalVariant)"
            :close-verified-compare="useComparisonTool && isCompareModalVariant && $vuetify.breakpoint.smAndDown"
            :show-share="(isReadOnlyModalVariant || isEditModalVariant || isCompareModalVariant || isSpecSheetModalVariant) && !!getRoutesForProductModal.length"
            :show-report="shouldShowReportLink"
            class="pa-0"
            @show-verified="changeProductModalVariant(TYPE_COMPARE)"
            @close-verified="changeProductModalVariant(TYPE_READONLY)"
            @share="copyToClipBoard(renderProductLink)"
            @report="reportProduct" />
          <span
            class="sourcery__icon-wrapper black"
            @click="showProductModal = false">
            <v-icon
              color="white"
              size="15">
              mdi-close
            </v-icon>
          </span>
        </div>
      </v-card-title>
      <v-card-text class="pa-0 d-flex">
        <div class="left-wrapper">
          <!-- adding: search -->
          <template v-if="isAddModalVariant">
            <div class="lightGrey">
              <div class="px-7 py-3">
                <h3 class="pb-2 fs-12 font-weight-bold font-fam-poppins darkGrey--text text-uppercase">
                  Search to follow an existing product
                </h3>
                <CreateProductSearch
                  :collection-id="collectionIdForGetSchema" />
              </div>

              <!-- adding: heading -->
              <h3 class="px-7 py-2 fs-12 font-weight-bold font-fam-poppins darkGrey--text text-uppercase">
                Create a custom product
              </h3>
            </div>
          </template>

          <!-- product info in both cases: adding + displaying -->
          <AppSearchRowsProvider
            :add-modal="isAddModalVariant"
            :cols="useComparisonTool && isCompareModalVariant && $vuetify.breakpoint.mdAndUp ? '6' : '12'"
            :search-wrapper-class="isReadOnlyModalVariant && !hasAccessToProduct && productCollectionName
              ? 'search-items__wrapper-collections-search'
              : 'search-items__wrapper-collections'"
            :items="renderHeaders"
            placeholder="Search by field name...">
            <template #items="{ searchItems, searchOn }">
              <div class="d-flex flex-column">
                <!-- compare view -->
                <div
                  v-if="isCompareModalVariant"
                  class="product-details-compare-wrapper">
                  <!-- icons -->
                  <div
                    v-if="$vuetify.breakpoint.lgAndUp"
                    class="d-flex flex-row justify-space-between px-3 pt-2 icon-wrapper">
                    <div class="d-flex gap-2">
                      <span class="sourcery__icon-wrapper green">
                        <img src="@/assets/icons/verified-icon.svg">
                      </span>
                      Verified Version
                    </div>
                    <v-icon
                      :size="20"
                      color="black"
                      class="white rounded-pill pointer"
                      @click="changeProductModalVariant(TYPE_READONLY);">
                      mdi-close-circle
                    </v-icon>
                  </div>
                </div>

                <div class="followed-product flex-grow-1">
                  <component
                    :is="(isCompareModalVariant) ? 'ProductCellFormListToComparison' : 'ProductCellFormList'"
                    :auto-hide-unpopulated-fields="autoHideUnpopulatedFields"
                    :collection-id="collectionIdForViewSchema"
                    :field-errors="fieldErrors"
                    :headers="searchItems"
                    :is-aside-form="isCompareModalVariant"
                    :is-dialog-collection="isDialogCollection"
                    :is-loading="isFetchingAny"
                    :loading-headers="loadingEditBlockedHeaders"
                    :item="(isCompareModalVariant) ? productsToComparison : item"
                    :mapped-dropdowns-of-view="getMappedDropdownsOfView"
                    :search-mode="searchOn"
                    view="productCreate"
                    @updateProductField="updateProductField" />

                  <RequestVerification
                    v-if="isCompareModalVariant && shouldShowDisclaimer"
                    @request="requestVerification" />
                </div>
              </div>
            </template>
          </AppSearchRowsProvider>
        </div>

        <!-- right content box -->
        <div
          v-if="$vuetify.breakpoint.lgAndUp && !isCompareModalVariant"
          class="right-wrapper d-flex flex-column gap-9">
          <!-- product share + compare toolbar -->
          <ActionsWrapper
            :show-verified-compare="useComparisonTool && !(isEditModalVariant || isCompareModalVariant)"
            :close-verified-compare="useComparisonTool && isCompareModalVariant && $vuetify.breakpoint.smAndDown"
            :show-share="(isReadOnlyModalVariant || isEditModalVariant || isCompareModalVariant || isSpecSheetModalVariant) && !!getRoutesForProductModal.length"
            :show-report="shouldShowReportLink"
            class="px-6 pt-6"
            @show-verified="changeProductModalVariant(TYPE_COMPARE)"
            @close-verified="changeProductModalVariant(TYPE_READONLY)"
            @share="copyToClipBoard(renderProductLink)"
            @report="reportProduct" />

          <!-- TODO: check what is this for -->
          <AppFormRow
            v-if="isReadOnlyModalVariant && !hasAccessToProduct && productCollectionName"
            class="flex-grow-0"
            header-text="Collection">
            <template #rowCell>
              <a @click="goNextPageBlank">
                {{ productCollectionName }}
              </a>
            </template>
          </AppFormRow>

          <div
            v-if="!isSpecSheetModalVariant"
            class="d-flex flex-column gap-4">
            <!-- disclaimer text -->
            <RequestVerification
              v-if="shouldShowDisclaimer"
              class="mx-6"
              @request="requestVerification" />

            <!-- to show tips and tricks -->
            <ProductCellFormList
              v-if="moveTipsAndTricks && !(isCompareModalVariant || isSpecSheetModalVariant)"
              :auto-hide-unpopulated-fields="autoHideUnpopulatedFields"
              :collection-id="collectionIdForViewSchema"
              :field-errors="fieldErrors"
              :headers="renderAsideHeaders"
              :is-aside-form="true"
              :is-dialog-collection="isDialogCollection"
              :is-loading="isFetchingAny"
              :item="item"
              :mapped-dropdowns-of-view="getMappedDropdownsOfView"
              :search-mode="false"
              view="productCreate"
              @updateProductField="updateProductField" />
          </div>

          <!-- to show spec sheet -->
          <div
            v-if="isSpecSheetModalVariant"
            class="flex-grow-1 d-flex flex-column align-stretch">
            <v-icon
              :size="20"
              color="black"
              class="white rounded-pill pointer align-self-end"
              @click="changeProductModalVariant(TYPE_READONLY);">
              mdi-close-circle
            </v-icon>
            <embed
              v-if="getProductModalSpecSheetUrl"
              :src="getProductModalSpecSheetUrl"
              class="flex-grow-1"
              type="application/pdf">
            <div v-else>
              Some error occured!
            </div>
          </div>
        </div>
      </v-card-text>
      <DialogActions
        :can-work-with-modify-product="canWorkWithModifyProduct"
        :user-role-in-collection="userRoleInCollection"
        :item="item"
        :followed-collection-id="followedCollectionId"
        :has-access-to-product="hasAccessToProduct"
        :is-store-product-preload="isStoreProductPreload"
        :selected-collection-to-create-product="selectedCollectionToCreateProduct"
        :product-id="productId"
        :editable="editable"
        :no-modal-for-duplicate-follow="noModalForDuplicateFollow"
        :no-modal-collection-id="noModalCollectionId"
        class="floating-actions"
        @save="onSave"
        @changeToEdit="changeToEdit"
        @is-followed-product="isFollowedProduct = $event"
        @show-product-modal="showProductModal = $event"
        @delete-current-product="deleteCurrentProduct" />
    </v-card>
    <!-- floating btn -->
    <v-btn
      class="white sourcery__dialog-close"
      fab
      large
      @click="showProductModal = false">
      <img src="@/assets/icons/chevron-right-icon.svg">
    </v-btn>
  </app-dialog>
</template>
<script>
// scraping takes a while, so let the success/error message last longer too or it gets missed!
const SCRAPING_SNACKBAR_TIMEOUT = 60000;

// core
import {
  mapState, mapMutations, mapActions, mapGetters,
} from 'vuex';

// constants
import PRODUCT_HEADERS, {
  SCRAPING_HEADERS,
  DEFAULT_PRODUCT_REQUIRED_FIELDS,
} from '@/constants/productHeaders';
import { FOLLOW_EDIT_REASON } from '@/constants/userPermissions';
import { COLLECTION } from '@/constants/cores';
import { INFO_EMAIL } from '@/constants/urls';
import {
  TYPE_FOLLOW,
  TYPE_DUPLICATE,
  TYPE_COMPARE,
  TYPE_EDIT,
  TYPE_READONLY, PRIVATE,
} from '@/constants';

// components
import AppFormRow from '@/components/App/AppFormRow';
import AppSearchRowsProvider from '@/components/App/AppSearchRowsProvider';
import CreateProductSearch from '@/components/Collections/CollectionsCreateProductDialog/CreateProductSearch';
import DialogActions from '@/components/Collections/CollectionsCreateProductDialog/DialogActions';
import ProductCellFormList from '@/components/Product/ProductCell/ProductCellCollectionsFormList';
import ProductCellFormListToComparison
  from '@/components/Product/ProductCell/ProductCellCollectionsFormList/ProductCellCollectionsFormListToComparison';

// mixins
import DisableEditMode from '@/mixins/DisableEditMode';
import updateCollectionProductField from '@/mixins/updateCollectionProductField';

// services
import API from '@/services/graphql';
import CollectionsApi from '@/services/graphql/collections';
import ComparisonApi from '@/services/graphql/comparison';

// utils
import RequestAbortController from '@/utils/RequestAbortController';
import { parseGetRows } from '@/utils/manageGetRows';
import {
  EventBus,
  DUPLICATED_PRODUCT_IN_CURR_COLL,
  EDITED_FOLLOWED_PRODUCT,
  FOLLOWED_PRODUCT_IN_CURR_COLL,
  SCRAPE_PRODUCT_DATA,
} from '@/utils/eventBus';
import {
  askAndPerform, isLimitOfProducts,
  copyToClipBoard, accessHelper, hasViewerAccess,
} from '@/utils';
import {
  isAsideHeader,
  sortProductDialogSchemaHeaders,
} from '@/utils/productHeaders';
import {
  setCollectionSchema,
  setCollectionSchemaMapping,
  setMappedDropdownsOfView,
  setSelectedCollectionView,
} from '@/utils/collectionSchemaAndViews';

export default {
  name: 'CollectionsCreateProductDialog',
  components: {
    AppFormRow,
    AppSearchRowsProvider,
    CreateProductSearch,
    DialogActions,
    ProductCellFormList,
    ProductCellFormListToComparison,
    RequestVerification: () => import('./RequestVerification'),
    ActionsWrapper: () => import('./ActionsWrapper'),
  },
  mixins: [DisableEditMode, updateCollectionProductField],
  props: {
    autoHideUnpopulatedFields: {
      type: Boolean,
      default: false,
    },
    canScrapeProduct: {
      type: Boolean,
      default: false,
    },
    collectionIdForGetSchema: {
      type: String,
      default: '',
    },
    editable: {
      type: Boolean,
      default: () => false,
    },
    isAllowComparison: {
      type: Boolean,
      default: true,
    },
    isDialogCollection: {
      type: Boolean,
      default: true,
    },
    isSourceLibraryId: {
      type: Boolean,
      default: false,
    },
    isStatusDialogFromState: {
      type: Boolean,
      default: true,
    },
    isStoreProductPreload: {
      type: Boolean,
      default: false,
    },
    localProduct: {
      type: Object,
      default: () => {
      },
    },
    noModalCollectionId: {
      type: String,
      default: null,
    },
    noModalForDuplicateFollow: {
      type: Boolean,
      default: false,
    },
    propLibraryId: {
      type: String,
      default: '',
    },
    sharedLinkCollectionId: {
      type: String,
      default: () => '',
    },
    statusDialog: {
      type: Boolean,
      default: false,
    },
    /**
     * Determines whether to use the same schema and views.
     *
     * @type {boolean}
     * @default false
     * @description If set to true, the component will use the same schema and views as the rendered page.
     */
    useTheSameSchemaAndViews: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      abortController: new RequestAbortController(),
      collectionsSchemaV2: {
      },
      fieldErrors: [],
      isFetching: {
        initialOpening: true,
        collectionViews: false,
        collectionSchema: false,
      },
      isFollowedProduct: false,
      origin: {
      },
      productsToComparison: [],
      productId: null,
      rowData: {
      },
      selectedCollectionToCreateProduct: null,
      selectedCollectionView: {
      },
      showTooltip: true,
      showProductComparison: false,
      TYPE_COMPARE,
      TYPE_DUPLICATE,
      TYPE_FOLLOW,
      TYPE_READONLY,
      // optimistic updating after editing followed product
      tempChildVerifiedWs: null,
      userRoleInCollection: null,
      viewMode: 'default',
    };
  },
  computed: {
    ...mapGetters('Collections', [
      'collectionGroupsForFollowProduct',
      'collectionsWithPermissions',
      'findSaveAsNewCollectionsById',
      'getProductModalSpecSheetUrl',
      'getRoutesForProductModal',
      'getSaveAsNewCollections',
      'isAddModalVariant',
      'isCompareModalVariant',
      'isDisallowClosingProductDialogAfterUpdate',
      'isEditModalVariant',
      'isReadOnlyModalVariant',
      'isSpecSheetModalVariant',
      'isUpdateReferencedProduct',
      'isSaveAsNewVariant',
      'updatedProduct',
    ]),
    ...mapGetters('FeatureFlags', [
      'useActionsProductFromSearch',
      'useEditedFollowComparisonTool',
      'useLightfairRequiredFields',
      'creatorUnableToCreateFollowProducts',
      'moveTipsAndTricks',
      'useLazyLoading',
    ]),
    ...mapGetters('Libraries', ['getDefaultLibraryId']),
    ...mapGetters('UserRoles', ['canFollowProduct', 'findUserRoleInLibraryForActiveHeader', 'usersRolesListInCollection']),
    ...mapState({
      generalAccessToken: state => state.accessToken,
    }),
    ...mapState(['showSpinner', 'activeHeader', 'isMobile']),
    ...mapState('Collections', ['privateCollectionsList', 'productToUpdate', 'publishedCollectionsList', 'productModalInvisible',
      'showCreateProductModal', 'productCollectionName', 'productCollectionLink', 'currentCollectionSharingOption', 'isCollectionInCustomLibrary']),
    ...mapState({
      isScrapingProductData: state => state.Collections.isFetching.scrapingProductData,
    }),
    ...mapState('Workspace', ['activeWorkspaceId']),
    accessToken() {
      return this.$route?.query?.accessToken;
    },
    canWorkWithModifyProduct() {
      const {
        useActionsProductFromSearch: flag,
        isEditableFromSearch,
        findUserRoleInLibraryForActiveHeader,
        usersRolesListInCollection,
        isUpdateReferencedProduct,
      } = this;
      const { members } = this.activeHeader || {
      };
      let role = findUserRoleInLibraryForActiveHeader;
      if (isEditableFromSearch && flag) return accessHelper(true);
      else if (isUpdateReferencedProduct) {
        role = this.roleInResource;
      } else if (members) {
        role = usersRolesListInCollection;
      }
      return this.canModifyProduct(role, this.isFollowedProduct && FOLLOW_EDIT_REASON);
    },
    childVerifiedWS() {
      return this.tempChildVerifiedWs || this.currentProduct?.childVerified;
    },
    collectionIdForViewSchema() {
      return this.collectionIdForGetSchema || this.collectionIdForCreateProduct || this.getDefaultLibraryId;
    },
    collectionsList() {
      return this.collectionsWithPermissions();
    },
    collectionsSchemaAccordingToFlag() {
      const { creatorUnableToCreateFollowProducts: flag, useTheSameSchemaAndViews } = this;
      return flag && !useTheSameSchemaAndViews ? this.collectionsSchemaV2 : this.collectionsSchema;
    },
    currentProduct() {
      const {
        isStatusDialogFromState = false,
        productToUpdate = {
        },
        localProduct = {
        },
      } = this;
      return !isStatusDialogFromState ? localProduct : productToUpdate;
    },
    formatedRequiredFields() {
      return this.requiredFieldsAccordingToResolution.map(el => {
        const item = this.schema.find((shemaObj) => shemaObj.id === el);
        return item.value;
      });
    },
    getMappedDropdownsOfView() {
      const { selectedCollectionView: view } = this;
      const { dropdowns } = view || {
      };
      return setMappedDropdownsOfView(dropdowns);
    },
    hasAccessToProduct() {
      const { useActionsProductFromSearch: flag, isEditableFromSearch, editable } = this;
      return (isEditableFromSearch && flag) || editable;
    },
    isDisabledCreateButton() {
      return (this.isAddModalVariant || this.isSaveAsNewVariant) && !this.selectedCollectionToCreateProduct?.id;
    },
    isEditableFromSearch() {
      return this.currentProduct?.editable || false;
    },
    isEditMode() {
      return this.currentProduct?.mode === 'edit';
    },
    isEditModalRelatedVariant() {
      const { isEditModalVariant, isUpdateReferencedProduct } = this;
      return isEditModalVariant || isUpdateReferencedProduct;
    },
    isFetchingAny() {
      if (!this.useLazyLoading) return;

      return this.isFetching.initialOpening
        || this.isFetching.collectionViews
        || this.isFetching.collectionSchema;
    },
    isVerifiedProduct() {
      return this.currentProduct?.isVerified;
    },
    item: {
      get() {
        return this.rowData;
      },
      set(value) {
        this.$set(this.rowData, value.col, value.value);
      },
    },
    loadingEditBlockedHeaders() {
      return this.isScrapingProductData ? SCRAPING_HEADERS : [];
    },
    removeText() {
      return 'This will DELETE the Product(s) from this Collection. Are you sure?';
    },
    renderAsideHeaders() {
      return this.schema.filter(isAsideHeader);
    },
    renderHeaders() {
      const predefined = [];
      predefined.push({
        text: PRODUCT_HEADERS.CHOOSE_COLLECTION,
        value: 'Choose Collection',
        selectValue: this.selectedCollectionToCreateProduct?.id,
        items: this.isSaveAsNewVariant ? this.getSaveAsNewCollections : this.collectionsList,
        column: {
          type: 'SELECT',
        },
      });
      return [...predefined, ...this.schema];
    },
    renderProductLink() {
      const workspace = `/workspace/${this.activeWorkspaceId}`;
      const isPrivateCollection = this?.currentCollectionSharingOption?.type == PRIVATE;
      const renderWsString = isPrivateCollection ? workspace : '';
      let additionalPath = '';
      const urlParams = new URLSearchParams();
      if (['shared-collection-product-link', 'embed-collection-product-link'].includes(this.$route.name)) {
        additionalPath = '/shared';
        Object.entries(this.$route.query).forEach(entry => {
          urlParams.set(entry[0], entry[1] ?? '');
        });
      }
      const query = urlParams?.toString();
      // return `https://${window.location.hostname}${renderWsString}/collection/${this.$route.params.id}${additionalPath}/product/${this.item.id}${query ? '?'.concat(query) : ''}`;
      return `https://${window.location.hostname}${renderWsString}/collection/${this.collectionIdForCreateProduct}${additionalPath}/product/${this.item.id}${query ? '?'.concat(query) : ''}`;
    },
    requiredFieldsAccordingToResolution() {
      const { requiredFields } = this.collectionsSchemaAccordingToFlag || {
      };
      if (!this.useLightfairRequiredFields || this.isMobile) {
        return requiredFields;
      }
      return DEFAULT_PRODUCT_REQUIRED_FIELDS;
    },
    schema() {
      const { schema } = this.collectionsSchemaAccordingToFlag || [];
      if (!schema) return [];
      return sortProductDialogSchemaHeaders(schema);
    },
    shouldShowDisclaimer() {
      return !this.isVerifiedProduct && !this.isAddModalVariant;
    },
    shouldShowReportLink() {
      return !this.isVerifiedProduct
      && (this.isReadOnlyModalVariant || this.isEditModalVariant || this.isCompareModalVariant || this.isSpecSheetModalVariant);
    },
    showProductModal: {
      get() {
        return this.isStatusDialogFromState ? this.updatedProduct : this.statusDialog;
      },
      set(value) {
        if (value === false) {
          this.$emit('productDialogClosed');
        }
        this.isStatusDialogFromState ? this.changeUpdateProductMode(value) : this.$emit('update:statusDialog', value);
      },
    },
    useComparisonTool() {
      const {
        useEditedFollowComparisonTool: flag,
        isEditMode,
        isAllowComparison,
        isAddModalVariant,
        usersRolesListInCollection,
        findUserRoleInLibraryForActiveHeader,
      } = this;
      return flag && !isAddModalVariant && isEditMode && isAllowComparison && hasViewerAccess(findUserRoleInLibraryForActiveHeader ?? usersRolesListInCollection);
    },
    verifiedPageId() {
      return this.currentProduct?.verifiedPageId;
    },
    verifiedPageName() {
      return this.currentProduct?.verifiedPageName;
    },
  },
  watch: {
    currentProduct(val) {
      if (val) {
        if (this.isUpdateReferencedProduct) {
          this.getSchemaAndViewsToCollection({
            collectionId: val?.referencedCollectionId,
          });
        } else {
          this.getProductId(val);
        }
      }
    },
    selectedCollectionToCreateProduct(value) {
      if (!value) return;
      const { id: collectionId } = value;
      const { creatorUnableToCreateFollowProducts: flag } = this;
      if (this.isSaveAsNewVariant || flag) {
        this.getSchemaAndViewsToCollection({
          collectionId,
        });
      }
    },
    async showProductModal(value) {
      if (!value) {
        if (!this.isAddModalVariant) {
          this.onCloseModal();
          this.productsToComparison = [];
        }
        if (this.getRoutesForProductModal.includes(this.$route.name)) {
          const matched = this.$route.matched;
          const { propLibraryId } = this;
          const libraryId = propLibraryId || this.$route.params?.libraryId;
          this.$router.replace({
            name: matched[matched.length - 2]?.name,
            params: {
              disableProxyDialog: true,
              id: this.$route.params.id,
              ...this.isCollectionInCustomLibrary && libraryId && {
                libraryId,
              },
            },
            query: {
              ...this.$route.query,
              ...!this.canWorkWithModifyProduct?.allowed
              && this.currentCollectionSharingOption?.type == 'unlistedPublish'
              && {
                accessToken: ['shared-collection-product-link', 'embed-collection-product-link'].includes(this.$route.name)
                  ? this.$route.query?.accessToken
                  : this.currentCollectionSharingOption?.accessToken,
              },
            },
          }).catch(() => {
          });
        }
        return;
      }
      if (!this.isAddModalVariant) this.initRowData();
      if (this.isUpdateReferencedProduct) {
        this.isFetching.initialOpening = false;
        return;
      }
      if (this.isSaveAsNewVariant) {
        this.showProductModalInSchedule();
        return;
      }
      this.showProductModalInCollections();
      this.checkComparisonExecution();
      await this.getUserRoleInCollection();
    },
  },
  created() {
    this.abortController = new RequestAbortController();
  },
  mounted() {
    EventBus.$on(FOLLOWED_PRODUCT_IN_CURR_COLL, () => {
      this.showProductModal = false;
    });
    EventBus.$on(DUPLICATED_PRODUCT_IN_CURR_COLL, () => {
      this.showProductModal = false;
    });
    EventBus.$on(EDITED_FOLLOWED_PRODUCT, () => {
      if (this.showProductModal) this.editedFollowedProduct;
    });
    if (this.canScrapeProduct) {
      EventBus.$on(SCRAPE_PRODUCT_DATA, ({ url }) => {
        if (this.showProductModal) this.scrapeProductDataFrom(url);
      });
    }
  },
  beforeDestroy() {
    this.abortController.abort();
  },
  destroyed() {
    EventBus.$off(FOLLOWED_PRODUCT_IN_CURR_COLL);
    EventBus.$off(DUPLICATED_PRODUCT_IN_CURR_COLL);
    EventBus.$off(EDITED_FOLLOWED_PRODUCT);
    if (this.canScrapeProduct) EventBus.$off(SCRAPE_PRODUCT_DATA);
    window.removeEventListener('resize', this.getSearchResultHeight);
  },
  methods: {
    askAndPerform,
    copyToClipBoard,
    ...mapMutations(['spinner', 'openSnackBar']),
    ...mapMutations('Collections', [
      'changeUpdateProductMode',
      'setProductToUpdate',
      'changeProductModalVariant',
      'changeProductCollectionLink',
      'changeProductCollectionName',
    ]),
    ...mapActions({
      handleError: 'handleError',
      getCustomLibraryCollectionsList: 'Collections/getCustomLibraryCollectionsList',
      getCollectionsSchemaV1: 'Collections/getCollectionsSchema',
      getCollectionsViewsV1: 'Collections/getCollectionsViews',
      getCollectionsList: 'Collections/getCollectionsList',
      getlistCollectionsForProduct: 'Collections/getlistCollectionsForProduct',
      deleteProduct: 'Collections/deleteProduct',
      deleteProductFromSearch: 'Collections/deleteProductFromSearch',
      scrapeProductData: 'Collections/scrapeProductData',
    }),
    async getUserRoleInCollection() {
      const { collectionId: resourceId } = this.item || {
      };
      if (!resourceId) return;
      try {
        const { data } = await API.getMyRoleInResource({
          workspaceId: this.activeWorkspaceId,
          resourceType: COLLECTION,
          resourceId,
        });
        this.userRoleInCollection = data.response;
      } catch (err) {
        console.error(err);
      }
    },
    async getProductToComparison() {
      try {
        const {
          activeWorkspaceId: workspaceId,
          getMasterCollection: tableId,
          getMasterProduct: rowId,
        } = this;
        if (!tableId || !rowId) {
          this.setProductsToComparison({
            origin: {
            },
            notAccessible: true,
          });
          return;
        }
        this.setProductsToComparison({
          loading: true,
          origin: {
          },
        });
        const { data } = await ComparisonApi.getRowDiffProduct({
          tableType: 'collection',
          tableId,
          rowId,
          rowVersionId: '',
          compareVersion: '',
          workspaceId,
        });
        const { fields = [] } = data?.response || {
        };
        const parsedFields = parseGetRows(fields);
        this.setProductsToComparison({
          origin: parsedFields,
        });
      } catch (e) {
        this.setProductsToComparison({
          origin: {
          },
          notAccessible: true,
        });
        console.error('getProductToComparison errr', e);
      } finally {
        this.spinner(false);
      }
    },
    async checkComparisonExecution() {
      const { useComparisonTool } = this;
      if (!useComparisonTool) return;
      const { isEditMode } = this;
      if (!isEditMode) return;
      this.getProductToComparison();
    },
    async deleteCurrentProduct() {
      const {
        isEditableFromSearch,
        followedCollectionId: collectionId = '',
        productId = '',
        deleteProduct,
        deleteProductFromSearch,
        removeText: text,
        useActionsProductFromSearch: flag,
      } = this;
      const action = isEditableFromSearch && flag ? deleteProductFromSearch : deleteProduct;
      const res = await this.$openConfirm({
        text,
      });
      if (!res) return;
      try {
        this.spinner(true);
        await action({
          collectionId,
          productIds: [productId],
        });
      } finally {
        this.spinner(false);
        this.showProductModal = false;
      }
    },
    async initRowData() {
      if (this.currentProduct) {
        this.rowData = this.currentProduct;
      } else {
        this.schema.forEach((item) => {
          this.$set(this.rowData, item.text, this.getInitialValue(item.column.type));
        });
      }
    },
    async showProductModalInSchedule() {
      this.spinner(true);
      await this.getlistCollectionsForProduct({
        projectId: this.rowData.projectId,
      });
      const item = this.findSaveAsNewCollectionsById(this.getDefaultLibraryId);
      const [firstCollection = null] = this.getSaveAsNewCollections || [];
      if (!isLimitOfProducts(item)) {
        this.selectedCollectionToCreateProduct = item || firstCollection;
      }
      this.spinner(false);
      this.isFetching.initialOpening = false;
    },
    async getSchemaAndViewsToCollection(data) {
      if (!data) return;
      if (!this.useLazyLoading) this.spinner(true);
      await this.getCollectionsViews(data);
      await this.getCollectionsSchema(data);
    },
    async getCollectionsViews(values) {
      const { creatorUnableToCreateFollowProducts: flag, propLibraryId: libraryId } = this;
      const data = {
        ...values,
        ...libraryId && {
          libraryId,
        },
      };
      try {
        this.isFetching.collectionViews = true;
        flag ? await this.getCollectionsViewsV2(data) : await this.getCollectionsViewsV1(data);
      } finally {
        this.isFetching.collectionViews = false;
      }
    },
    async getCollectionsSchema(values) {
      const { creatorUnableToCreateFollowProducts: flag, propLibraryId: libraryId } = this;
      const data = {
        ...values,
        ...libraryId && {
          libraryId,
        },
      };
      try {
        this.isFetching.collectionSchema = true;
        flag ? await this.getCollectionsSchemaV2(data) : await this.getCollectionsSchemaV1(data);
      } finally {
        this.isFetching.collectionSchema = false;
      }
    },
    async getCollectionsViewsV2(values = {
    }) {
      try {
        const { generalAccessToken: accessToken, activeWorkspaceId: workspaceId } = this;
        const { data } = await CollectionsApi.collectionsViews({
          ...values,
          workspaceId,
          ...(accessToken && {
            accessToken,
          }),
        });
        const { response: views } = data;
        this.selectedCollectionView = setSelectedCollectionView(views);
      } catch (err) {
        console.error('getCollectionsViews err', err);
      }
    },
    async getCollectionsSchemaV2(values = {
    }) {
      try {
        const { generalAccessToken: accessToken, activeWorkspaceId: workspaceId } = this;
        const { data } = await CollectionsApi.collectionsSchema({
          ...values,
          workspaceId,
          ...(accessToken && {
            accessToken,
          }),
        });
        const { response: collectionsSchema } = data || {
        };
        const { schema } = collectionsSchema || {
        };
        this.collectionsSchemaV2 = setCollectionSchema({
          selectedCollectionView: this.selectedCollectionView,
          collectionsSchemaMapping: setCollectionSchemaMapping(schema),
          collectionsSchema,
        });
      } catch (err) {
        console.error('getCollectionsSchema err', err);
      }
    },
    async getCollectionList() {
      const { getDefaultLibraryId: libraryId } = this;
      await Promise.all(this.collectionGroupsForFollowProduct.map(async item => (
        await this.getCollectionsList({
          libraryId,
          collectionGroup: item.group,
          limit: 99,
          showSpinner: false,
          isToAutocomplete: true,
          forProduct: true,
          accessToken: this.accessToken,
          route: this.$route,
        })
      )));
    },
    async getLibraryCollectionList() {
      const { propLibraryId: libraryId, activeWorkspaceId: workspaceId } = this;
      const isAutocomplete = true;
      await this.getCustomLibraryCollectionsList({
        libraryId,
        workspaceId,
        isAutocomplete,
        route: this.$route,
      });
    },
    async showProductModalToCreating() {
      this.selectedCollectionToCreateProduct = null;
      const { isSourceLibraryId } = this;
      isSourceLibraryId ? await this.getLibraryCollectionList() : await this.getCollectionList();
      if (!this.collectionsList.length) return;
      let collection = this.collectionsList[0];
      const route = this.$route;
      if (route.name == 'collection-library') {
        const colId = route.params.id;
        collection = this.collectionsList.find(col => col.id == colId);
      }
      if (!isLimitOfProducts(collection)) {
        this.selectedCollectionToCreateProduct = collection;
      }
    },
    async showProductModalInCollections() {
      if (!this.useLazyLoading) this.spinner(true);

      const {
        collectionIdForViewSchema,
        creatorUnableToCreateFollowProducts: flag,
        useTheSameSchemaAndViews,
      } = this;
      const collectionId = this.sharedLinkCollectionId ? this.sharedLinkCollectionId : collectionIdForViewSchema;
      if (this.isAddModalVariant) {
        await this.showProductModalToCreating();
        this.isFetching.initialOpening = false;
        if (flag) return;
      }
      if (flag && useTheSameSchemaAndViews) {
        this.spinner(false);
        this.isFetching.initialOpening = false;
        return;
      }
      this.getSchemaAndViewsToCollection({
        collectionId,
      });
      this.isFetching.initialOpening = false;
    },
    async onCloseModal() {
      this.rowData = {
      };
      this.fieldErrors = [];
      this.selectedCollectionToCreateProduct = null;
      this.collectionsSchemaV2 = {
      };
      this.selectedCollectionView = {
      };
      this.setProductToUpdate(null);
      this.changeProductModalVariant(null);
      this.changeProductCollectionLink('');
      this.changeProductCollectionName('');
      this.isFetching.initialOpening = true;
      this.abortController.abort();
    },
    async onSave() {
      if (this.showSpinner) {
        return;
      }
      const canSave = await this.allowToSave(this.currentProduct?.mode, this.isUpdateReferencedProduct ? this.item?.referencedProductId : this.currentProduct?.id);
      if (!canSave) {
        return;
      }
      this.checkValidateErrors();
      const {
        saveProduct,
        addProduct,
        isUpdateReferencedProduct,
        isEditModalRelatedVariant,
        closingModalLogic,
      } = this;
      if (this.fieldErrors.length === 0) {
        let productToCreate = [];
        this.schema.forEach((s) => {
          for (const element in this.item) {
            if (this.item[element] === undefined) {
              this.item[element] = '';
            }
          }
          if ((isEditModalRelatedVariant) && this.item[s.text] !== undefined
            || this.item && this.item[s.text]?.length !== 0 && this.item[s.text] !== undefined) {
            productToCreate.push(this.generateProduct({
              id: s.id,
              value: this.item[s.text] || '',
            }));
          }
        });
        const product = (isEditModalRelatedVariant) && !this.isCreateNewProduct ? await saveProduct({
          product: productToCreate,
          productId: this.item.id,
          ...(isUpdateReferencedProduct && {
            collectionId: this.item?.referencedCollectionId,
            productId: this.item?.referencedProductId,
          }),
        }) : await addProduct(productToCreate);
        closingModalLogic();
        this.$emit('savedProduct', product);
        this.isCreateNewProduct = false;
      }
    },
    changeToEdit() {
      this.changeProductModalVariant(TYPE_EDIT);
    },
    checkValidateErrors() {
      let errors = [];
      this.formatedRequiredFields.forEach((f) => {
        if (!this.item[f] || !this.item[f].length) {
          errors.push(f);
        }
      });
      this.fieldErrors = errors;
    },
    closingModalLogic() {
      const { isDisallowClosingProductDialogAfterUpdate: isDisallow } = this;
      if (isDisallow) {
        this.changeProductModalVariant(TYPE_READONLY);
        return;
      }
      this.showProductModal = false;
      this.onCloseModal();
      this.productsToComparison = [];
      this.initRowData();
    },
    editedFollowedProduct(product) {
      if (!product || !this.isVerifiedProduct) return;

      for (const field of product.fields) {
        if (field.name === PRODUCT_HEADERS.CHILD_VERIFIED) {
          this.tempChildVerifiedWs = field.string;
          break;
        }
      }
    },
    getInitialValue(item) {
      if (item.includes('ARRAY')) {
        return [];
      } else {
        return '';
      }
    },
    getProductId(val) {
      const { SK } = val ?? {
      };
      if (!SK) {
        this.productId = '';
        return;
      }
      const [, productId] = SK.split('ROW#') || [];
      if (!productId) {
        this.productId = '';
        return;
      }
      this.productId = productId;
    },
    goNextPageBlank() {
      const routeData = this.$router.resolve(this.productCollectionLink);
      window.open(routeData.href, '_blank');
    },
    reportProduct() {
      const subject = 'Request to report a product';
      let body = `I would like to report a product:\n\r${this.renderProductLink}\n\r\n\r`;
      body = encodeURIComponent(body);

      window.open(`mailto:${INFO_EMAIL}?subject=${subject}&body=${body}`);
    },
    requestVerification() {
      const subject = 'Request Verification';
      let body = `I would like to request verification for a product:\n\r${this.renderProductLink}\n\r\n\r`;
      body = encodeURIComponent(body);

      window.open(`mailto:${INFO_EMAIL}?subject=${subject}&body=${body}`);
    },
    setProductsToComparison({ origin, follow, loading = false, notAccessible = false } = {
    }) {
      this.origin = origin;
      this.productsToComparison = [
        {
          shortTitle: 'Reference',
          title: 'Verified Product Reference (Read Only)',
          product: origin,
          readOnly: true,
          loading,
          notAccessible,
        },
        {
          shortTitle: 'Follow',
          title: 'Your Product Reference (Editable)',
          readOnly: false,
          product: follow || this.item,
          loading: false,
        },
      ];
    },
    showPensil(type) {
      return ['DATE', 'ARRAY_AIRTABLE_IMAGE', 'COMBOBOX', 'DROPDOWN'].includes(type);
    },
    async scrapeProductDataFrom(url) {
      if (this.isScrapingProductData) return;

      try {
        this.abortController = new RequestAbortController();
        const scrapedProduct = await this.scrapeProductData({
          url: url,
          abortController: this.abortController,
        });
        const filledFields = await this.fillInScrapedData(scrapedProduct);
        if (filledFields.length > 0) {
          this.openSnackBar({
            title: 'Filled data for:',
            text: filledFields.join(', '),
            timeout: SCRAPING_SNACKBAR_TIMEOUT,
          });
        } else {
          throw new Error('No data from url - no fields filled?');
        }
      } catch (err) {
        if (this.abortController?.aborted()) {
          return;
        }
        console.error('Error in web scraping product data', err);
        this.openSnackBar({
          text: 'Sorry, but  we could not import this product.\n At least we tried!',
          timeout: SCRAPING_SNACKBAR_TIMEOUT,
        });
      }
    },
    async fillInScrapedData(productData) {
      const filledFields = [];
      for (const col in productData) {
        let val = productData[col];
        if (col == PRODUCT_HEADERS.IMAGE) {
          try {
            val = [val[0].id];
          } catch (err) {
            console.warn('Error filling in scraped Image data', err);
            continue;
          }
        }
        // We need to check for '0' strings due to how the unit_price + GQL response works
        // I kinda want to change it, but changing how we handle price numbers can be scary.
        if (!val || Array.isArray(val) && val.length === 0 || val == '0') {
          continue;
        }
        filledFields.push(col);
        this.item = {
          col: col,
          value: val,
        };
      }
      return filledFields;
    },
  },
};
</script>