<template>
  <div
    v-if="activeHeader"
    class="collection-header">
    <template v-if="!renameItem">
      <!-- image hidden for collection -->
      <div
        v-if="false"
        class="collection-header__image">
        <ListingImage
          :collection="activeHeader"
          :pictures="setPictures(activeHeader)" />
      </div>

      <!-- title -->
      <span class="collection-header__title black--text font-fam-poppins mt-6">
        {{ activeHeader.name }}
      </span>

      <!-- view as member -->
      <div
        v-if="viewAsMember"
        class="collection-header__member-view">
        {{ viewAsMember.text }}
        <a
          :href="viewMemberLink"
          target="_blank">{{ viewAsMember.name }}</a>
      </div>

      <!-- author -->
      <div
        v-if="!createLibrary"
        class="collection-header__author fs-16 darkGrey--text">
        <!-- author name -->
        <span v-if="activeHeader.author">
          {{ activeHeader.author }}
        </span>
        <v-icon v-if="activeHeader.author && renderAddressText">
          mdi-circle-small
        </v-icon>
        <!-- author address -->
        <span v-if="renderAddressText">
          {{ renderAddressText }}
        </span>
      </div>

      <!-- description -->
      <span
        v-if="activeHeader.description"
        class="collection-header__description mt-3">
        {{ activeHeader.description }}
      </span>

      <!-- buttons -->
      <CollectionHeaderActions
        class="mt-8"
        :active-header="activeHeader"
        :can-work-with-modify-product="canWorkWithModifyProduct"
        :is-community-collections="isCommunityCollections"
        :is-editing="renameItem"
        :is-library="isLibrary"
        :is-shared-collection="isSharedCollection"
        :read-mode="readMode"
        @rename="canWorkWithModifyProduct.allowed && rename()">
        <template #btns-start>
          <slot name="btns-start" />
        </template>
      </CollectionHeaderActions>
    </template>

    <!-- editing -->
    <div
      v-if="renameItem"
      class="collection-container">
      <!-- logo edito -->
      <HeaderLogos
        :text-crop="isLibrary ? 'Crop to library logo aspect ratio' : 'Crop to collection logo aspect ratio'"
        :active-header="activeHeader"
        @addLogo="addLogo" />

      <!-- collection name -->
      <v-text-field
        ref="editInput"
        :value="editName"
        autocomplete="off"
        class="edit-input-collection-name"
        color="black"
        placeholder="Enter project name"
        @input="edit" />

      <template v-if="!createLibrary">
        <!-- author -->
        <v-text-field
          ref="editAuthor"
          :value="editAuthor"
          placeholder="Edit author"
          autocomplete="off"
          @input="(v) => (editAuthor = v)" />

        <!-- location -->
        <AppGoogleLocationField
          v-if="renameItem"
          :current-location="renderAddressText"
          @setLocationData="editLocation = $event"
          @setAddressData="editAddress = $event" />
        <v-text-field
          v-else
          id="placeAuto"
          :value="editLocation"
          autocomplete="off"
          class="google-input"
          placeholder="Location"
          @blur="() => (editLocation = address)"
          @focus="() => (editLocation = address)"
          @input="(e) => (editLocation = e)" />
      </template>

      <!-- description -->
      <v-textarea
        ref="editInput"
        :rows="3"
        :value="editDescription"
        autocomplete="off"
        color="black"
        placeholder="Enter Description"
        outlined
        @input="editDesc" />

      <!-- save btn when editing -->
      <div class="d-flex justify-center gap-3">
        <v-btn
          class="white--text"
          color="blue"
          elevation="0"
          @click="saveEditedName(activeHeader)">
          Save
        </v-btn>
        <v-btn
          outlined
          @click="renameItem = null">
          Cancel
        </v-btn>
      </div>
    </div>

    <!-- share dialog -->
    <share-advanced-dialog
      v-if="renderSharingInHeader"
      :disable-actions="disableActions"
      :disabled="!(disableActions && !activeHeader.follow)"
      :invite-to-text-type="COLLECTION"
      :item="activeHeader"
      @manageSharedUser="
        manageSharedUser({
          variables: {
            ...$event.variables,
            collectionId: activeHeader.id,
          },
          criteria: $event.criteria,
        })
      " />
  </div>
</template>
<script>
import {
  mapActions, mapMutations, mapState, mapGetters,
} from 'vuex';
import { omit } from 'lodash';
import { ROUTE_COMMUNITY_COLLECTION } from '@/constants';
import {
  COLLECTION, LIBRARY,
} from '@/constants/cores';

import CollectionsApi from '@/services/graphql/collections';
import LibrariesApi from '@/services/graphql/libraries';

import AppGoogleLocationField from '@/components/App/AppFields/AppGoogleLocationField';
import CollectionHeaderActions from '@/components/Listing/CollectionHeader/CollectionHeaderActions';
import HeaderLogos from '@/components/CollectionsLibrarysRelocate/Logos';
import ListingImage from '@/components/Listing/ListingImageItem/ListingImage';
import ShareAdvancedDialog from '@/components/CollectionsLibrarysRelocate/ShareAdvancedDialog';

import ManageCollectionsActions from '@/mixins/ManageCollectionsActions';
import setAutocompletePlace from '@/mixins/setAutocompletePlace';
import SetPictures from '@/mixins/SetPictures';

export default {
  name: 'CollectionHeader',
  components: {
    AppGoogleLocationField,
    CollectionHeaderActions,
    HeaderLogos,
    ListingImage,
    ShareAdvancedDialog,
  },
  mixins: [
    ManageCollectionsActions,
    setAutocompletePlace,
    SetPictures,
  ],
  props: {
    viewAsMember: {
      type: Object,
      default: null,
    },
    isCommunityCollections: {
      type: Boolean,
      default: false,
    },
    isSharedCollection: {
      type: Boolean,
      default: false,
    },
    isLibrary: {
      type: Boolean,
      default: false,
    },
    activeHeader: {
      type: Object,
      default: () => ({
      }),
    },
    readMode: {
      type: Boolean,
      default: false,
    },
    renderSharingInHeader: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      COLLECTION,
      renameItem: null,
      editName: null,
      editAuthor: null,
      editLocation: null,
      editAddress: null,
      editDescription: null,
      logo: null,
      address: null,
      // to prevent error(not an instance of HTMLInputElement) in console
      initializeGoogleAutocompleteOnMounted: false,
      generalCommentsModal: false,
    };
  },
  computed: {
    ...mapState('Workspace', ['activeWorkspaceId']),
    ...mapGetters(['userId', 'premiumGroup']),
    ...mapGetters('UserRoles', [
      'findUserRoleInLibraryForActiveHeader',
      'canModifyCollection',
      'canUpdateInfoInCustomLibrary',
    ]),
    ...mapGetters('Collections', [
      'isGroupFollowed',
    ]),
    renderAddress() {
      const { editLocation: location, editAddress: address } = this;
      const addressObject = {
        location,
        address,
      };
      const paramToOmit = address ? 'location' : 'address';
      return omit(addressObject, [paramToOmit]);
    },
    renderAddressText() {
      const { location, address } = this.activeHeader;
      return address?.formatted_address || location;
    },
    viewMemberLink() {
      return this.$router.resolve({
        name: 'collection-library',
        params: {
          wId: this.activeHeader?.workspaceId,
          id: this.$route.params.id,
        },
      })?.href;
    },
    canWorkWithModifyProduct() {
      if (this.activeHeader.docType == LIBRARY) {
        return this.canUpdateInfoInCustomLibrary(this.findUserRoleInLibraryForActiveHeader);
      }
      return this.canModifyCollection(this.findUserRoleInLibraryForActiveHeader);
    },
    createLibrary() {
      return ['community-collections', 'collections'].includes(
        this.$route.name
      );
    },
    disableActions() {
      return ![ROUTE_COMMUNITY_COLLECTION, 'community-collections'].includes(
        this.$route.name
      );
    },
    daysToExpiredPublishLeft() {
      const expDate = this.activeHeader?.publishExpirationDate;
      if (expDate) {
        let endDate = this.$moment(new Date(Number.parseInt(expDate)));
        let now = this.$moment();
        return endDate.diff(now, 'days');
      }
      return null;
    },
  },
  watch: {
    'activeHeader.publishExpirationDate'(val) {
      if (val) this.showBunnerUnpublishedCollection();
    },
  },
  destroyed() {
    this.closeBanner();
  },
  methods: {
    ...mapMutations(['spinner', 'setBanner', 'closeBanner']),
    ...mapActions({
      updateActiveListingElement: 'updateActiveListingElement',
      manageSharedUser: 'Collections/manageSharedUser',
      updateLibraryName: 'Libraries/updateLibraryName',
      updateCollectionName: 'Collections/updateCollectionName',
      handleError: 'handleError',
      openCreateProductModal: 'Collections/openCreateProductModal',
    }),
    showBunnerUnpublishedCollection() {
      const { publishExpirationMessage } = this.activeHeader;
      if (!publishExpirationMessage) return false;
      //puting count days in text
      const message = publishExpirationMessage.replace('*', this.daysToExpiredPublishLeft);
      this.setBanner({
        value: true,
        message,
      });
    },
    edit(e) {
      this.editName = e;
    },
    editDesc(e) {
      this.editDescription = e;
    },
    rename() {
      this.renameItem = this.activeHeader;
      this.editName = this.activeHeader.name;
      this.editDescription = this.activeHeader.description;
      this.editAuthor = this.activeHeader.author;
      this.address = this.activeHeader.location;
      this.editLocation = this.activeHeader.location;
      this.editAddress = this.activeHeader.address;
      this.$nextTick(() => {
        this.$refs.editInput.focus();
      });
    },
    async saveEditedName(element) {
      if (!this.editName) {
        this.renameItem = null;
      } else {
        this.spinner(true);
        try {
          const logo =
            typeof this.logo === 'string' ? this.logo : this.activeHeader.logo;
          let variables = {
            libraryId: this.createLibrary ? element.id : element.libraryId,
            collectionId: this.createLibrary ? '' : element.id,
            name: this.editName,
            logo,
            description: this.editDescription,
          };
          const workspaceId = this.activeWorkspaceId;
          const { data } = this.createLibrary
            ? await LibrariesApi.update({
              ...variables,
              workspaceId,
            })
            : await CollectionsApi.update({
              ...variables,
              workspaceId,
              author: this.editAuthor,
              ...this.renderAddress,
            });
          this.createLibrary
            ? this.updateLibraryName(data.response)
            : this.updateCollectionName(data.response);
          this.updateActiveListingElement(data.response);
        } catch (err) {
          this.handleError(err);
        }
        this.renameItem = null;
        this.spinner(false);
      }
    },
    addLogo(item) {
      this.logo = item;
    },
    filteredMembers(members) {
      return members ? members.filter((member) => member) : [];
    },
  },
};
</script>
<style lang="scss">
.collection-header {
  align-items: center;
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 850px;
  text-align: center;

  &__image {
    height: 115px;
    width: 175px;
    border-radius: 10px;
    box-shadow: 0px 0px 25px 0px rgba(34, 34, 34, 0.15);
    overflow: hidden;
  }

  &__title {
    font-size: 42px;
    font-weight: 700;
    line-height: normal;
  }

  &__description {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    text-align: center;
  }

  &__author {
    line-height: 24px;
  }

  &__member-view {
    background-color: #E3F8FB;
  }

  .collection-container {
    max-width: 600px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;

    .v-text-field {
      height: auto !important;
      margin: 0 !important;
      padding: 0 !important;
      .v-text-field__slot {
        padding-bottom: 5px !important;
      }
    }
    textarea {
      overflow: auto !important;
    }
  }
}
</style>
