<template>
  <div>
    <WorkspaceCustomMembersDialog
      v-if="dialogType === TYPE_COLLECTION_SHARE && showWorkspaceAddMemberModal"
      :custom-members-dialog.sync="customMembersDialog"
      :invited-users="invitedUsersForShareWorkspace" />
    <v-form
      ref="shareForm"
      style="width: 100%"
      @submit.prevent="">
      <v-row
        class="mx-sm-6 mx-4 mt-8 mb-0">
        <v-col
          class="pa-0"
          :sm="showEditOfSharing ? '9' : '12'"
          cols="12">
          <InteractiveTooltip
            content-class="btn-tooltip"
            top>
            <template v-slot:activator="{ on }">
              <div v-on="guestLimitReached ? on : ''">
                <ShareAutocomplete
                  collection-share
                  check-email-errors
                  shared-autocomplete
                  higlight-users
                  :disabled="guestLimitReached"
                  :invite-to-text-type="inviteToTextType"
                  :select-errors="selectErrors"
                  @selected="selectedUsers" />
              </div>
            </template>
            <span>
              To invite more guest users, upgrade your workspace
              <router-link :to="getFullLinkForWorkspaces('workspace-payment')">here</router-link>
            </span>
          </InteractiveTooltip>
        </v-col>
        <v-col
          v-if="showEditOfSharing"
          class="pa-0"
          sm="3"
          cols="12">
          <v-autocomplete
            v-model="permission_type"
            autocomplete="off"
            :items="permissionTypes"
            solo
            flat
            filled
            dense
            hide-details
            hide-no-data
            class="v-autocomplete__share-groups v-autocomplete__share-groups--type">
            <template #item="data">
              <v-tooltip
                right
                max-width="200">
                <template #activator="{ on, attrs }">
                  <span
                    v-bind="attrs"
                    class="grow"
                    v-on="on">{{ data.item.text }}</span>
                </template>
                <span>{{ data.item.message }}</span>
              </v-tooltip>
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
      <p
        v-if="selectErrors"
        class="ml-6 mr-6 mt-2 mb-2 error--text">
        {{ selectErrors }}
        <b
          @click="pushToPayment">
          subscribe.
        </b>
      </p>
      <p
        v-if="errorLimitMessage"
        class="ml-6 mr-6 mt-2 mb-2 error--text">
        {{ errorLimitMessage }}
      </p>
      <p
        v-if="showValidationEmailErrors && validationEmailErrors.length"
        class="ml-6 mr-6 mt-2 mb-2 error--text">
        {{ ErrorsText.WRONG_EMAILS_FOR_SHARING(validationEmailErrors) }}
      </p>
    </v-form>
    <div
      v-if="!showEditOfSharing || selectErrors"
      style="min-height: 250px; max-height: 38vh; overflow: auto;"
      class="pt-1">
      <div
        v-if="isLoading"
        class="ml-2 mr-12 mt-2">
        <v-skeleton-loader
          v-for="n in 3"
          :key="n"
          type="list-item-avatar-two-line" />
      </div>
      <v-row
        v-for="(permission, i) in filteredPermissions"
        :key="i"
        class="ml-6 mr-10 mt-8">
        <template v-if="getRoleByPermission(permission)">
          <AvatarImage
            :avatar="avatars[permission.picture]"
            :avatar-key="permission.picture"
            :color="permission.id"
            :add-additional-size-to-icon="false"
            :size="40" />
          <v-col
            class="pa-0 d-flex"
            sm="11"
            cols="12"
            :class="{'pl-3': $vuetify.breakpoint.mdAndUp }">
            <v-row class="ma-0 d-flex justify-space-between align-center">
              <UserInfo :user-info="permission" />
              <v-menu
                :disabled="disabledRoleShared(permission,canWorkWithModifyShare)"
                offset-y
                nudge-bottom="10"
                content-class="edit-menu">
                <template #activator="{ on: onMenu }">
                  <v-tooltip
                    content-class="btn-tooltip"
                    :disabled="canWorkWithModifyShare || isCurrentUser(permission.id)"
                    bottom>
                    <template #activator="{ on: onTooltip }">
                      <div
                        class="d-flex"
                        v-on="permission.name ? { ...onMenu, ...onTooltip } : null">
                        <p class="small-p title-in-modal mb-0 pointer text-capitalize">
                          {{ getRoleByPermission(permission) }}
                        </p>
                        <span style="width: 20px; height: 20px">
                          <v-icon
                            v-if="permission.name && !disabledRoleShared(permission,canWorkWithModifyShare)"
                            class="mb-1"
                            size="20px"
                            color="black">
                            mdi-menu-down
                          </v-icon>
                        </span>
                      </div>
                    </template>
                    <span>{{ NOT_PERMITTED_ACCESS }}</span>
                  </v-tooltip>
                </template>
                <v-card class="nav-card">
                  <template v-for="type in permissionTypes">
                    <v-tooltip
                      :key="type.value"
                      right
                      max-width="200">
                      <template v-slot:activator="{ on, attrs }">
                        <p
                          :class="{'highlighted' : type.value === getRoleByPermission(permission) }"
                          v-bind="attrs"
                          v-on="on"
                          @click="updateType(permission.id, type.value)">
                          {{ type.text }}
                        </p>
                      </template>
                      <span>{{ type.message }}</span>
                    </v-tooltip>
                  </template>
                  <v-divider class="mb-1" />
                  <p
                    @click="deletePermission(permission.id)">
                    Remove
                  </p>
                </v-card>
              </v-menu>
            </v-row>
          </v-col>
        </template>
      </v-row>
      <LibrariesPermissions
        v-if="useLibraryPermissionsNewWay"
        :library-permissions-list="permissionsByLibrary" />
    </div>
    <div
      v-else-if="[TYPE_WORKSPACE_SHARE , TYPE_COLLECTION_SHARE].includes(dialogType)"
      style="min-height: 250px; max-height: 38vh;"
      class="pt-1 ml-6 mr-6">
      <div>
        <div
          class="d-block">
          <v-textarea
            v-model="shareMessage"
            class="c-textarea"
            placeholder="Message" />
        </div>
      </div>
    </div>
    <v-card-actions v-if="!showEditOfSharing">
      <v-spacer />
      <p
        v-if="isChanged"
        class="mb-0 small-p title-in-modal mr-2 font-italic">
        Pending changes
      </p>
      <v-btn
        v-if="isChanged"
        color="blue"
        class="white--text"
        elevation="0"
        @click="checkSaveChanges('update')">
        Save
      </v-btn>
      <v-btn
        v-else
        color="blue"
        class="white--text"
        elevation="0"
        @click="$emit('closeModal')">
        Done
      </v-btn>
    </v-card-actions>
    <v-card-actions v-else>
      <v-spacer />
      <v-btn
        class="mr-3"
        outlined
        @click="showEditOfSharing = !showEditOfSharing">
        Cancel
      </v-btn>
      <app-btn
        :text="NOT_PERMITTED_ACCESS"
        :show-tooltip="!!selectErrors || !canWorkWithModifyShare"
        :disabled="!!selectErrors || !canWorkWithModifyShare || !!errorLimitMessage"
        btn-class="white--text"
        color="blue"
        elevation="0"
        bottom
        @click="checkSaveChanges">
        Send
      </app-btn>
    </v-card-actions>
  </div>
</template>
<script>
import {
  CONFIRM_TO_PROCEED_IF_FREE_USERS,
  TYPE_COLLECTION_SHARE, TYPE_WORKSPACE_SHARE,
} from '@/constants';
import {
  TYPE_MEMBER, NOT_PERMITTED_ACCESS, TYPE_VIEWER,
  GET_PERMISSION_TYPES,
} from '@/constants/userPermissions';
import ErrorsText from '@/constants/errors';
import {
  mapActions, mapGetters, mapMutations, mapState,
} from 'vuex';
import userProfileAPI from '@/services/graphql/userProfile';
import {
  isValidEmail,
  getFullLinkForWorkspaces,
} from '@/utils';
import ShareAutocomplete from '@/components/App/AppShareAutocomplete';
import InteractiveTooltip from '@/components/App/AppInteractiveTooltip';
import WorkspaceCustomMembersDialog from '@/components/Workspace/WorkspaceCustomMembersDialog';
import ShareMixin from '@/mixins/ShareMixin';
import { isObject } from 'lodash';
export default {
  name: 'ShareContent',
  components: {
    InteractiveTooltip,
    ShareAutocomplete,
    WorkspaceCustomMembersDialog,
    UserInfo: () => import('@/components/App/AppShareModal/AppShareModalUserInfo'),
    LibrariesPermissions: () => import('@/components/CollectionsLibrarysRelocate/ShareContent/ShareContentLibrariesPermissions'),
  },
  mixins: [ShareMixin],
  props: {
    statusDialog: {
      type: Boolean,
      default: false,
    },
    wsGuests: {
      type: Array,
      default: () => [],
    },
    members: {
      type: Array,
      default: () => [],
    },
    showSelectErrors: {
      type: Boolean,
      default: false,
    },
    permissionTypes: {
      type: Array,
      default: () => GET_PERMISSION_TYPES(['viewer', 'editor', 'creator']),
    },
    dialogType: {
      type: String,
      default: TYPE_COLLECTION_SHARE,
    },
    inviteToTextType: {
      type: String,
      default: undefined,
    },
    item: {
      type: Object,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    permission_type: 'viewer',
    isChanged: false,
    shareMessage: '',
    showValidationEmailErrors: false,
    changedUsers: {
      updatePermission: [],
      deletePermission: [],
      createPermission: [],
      inviteUsers: [],
    },
    // This is a temp data structure to hold users/perms when adding to avoid dupes.
    // Should really not be needed and code be rewritten.
    intersections: [],
    permissions: [],
    clonedPermissions: [],
    addToWorkspaceMembers: [],
    saveType: '',
    TYPE_COLLECTION_SHARE,
    TYPE_WORKSPACE_SHARE,
    NOT_PERMITTED_ACCESS,
    ErrorsText,
  }),
  computed: {
    addedWsUsers() {
      return this.addToWorkspaceMembers.reduce((result = {
      }, user) => {
        const guest = this.checkIfMemberOrGuest(this.wsGuests, user);
        if (guest) {
          if (!result?.guests) {
            result.guests = [];
          }
          const { id = null, email = null } = guest?.userInfo ?? {
          };
          result.guests.push({
            id,
            email,
            type: this.permission_type,
          });
          return result;
        }
        const member = this.checkIfMemberOrGuest(this.members, user);
        if (member) {
          if (!result?.members) {
            result.members = [];
          }
          result.members.push(user);
          return result;
        }
        if (!result?.other) {
          result.other = [];
        }
        result.other.push(user);
        return result;
      }, {
      });
    },
    ...mapState([
      'avatars',
      'userInfo',
    ]),
    ...mapState('Collections', ['privateCollectionsList']),
    ...mapState('Workspace', ['invitedUsersForShareWorkspace']),
    ...mapGetters('Workspace', ['getActiveWorkspace', 'hasHitGuestLimit']),
    ...mapGetters(['userId']),
    ...mapGetters('FeatureFlags', ['showWorkspaceAddMemberModal', 'corePackageUpdates']),
    ...mapGetters('UserRoles', ['canShareCollectionWithLimit',
      'canShareCollectionWithNoLimit', 'findUserRoleInLibraryForActiveHeader',
      'disabledRoleShared', 'canShareCustomLibrary', 'canShareLibraryOrCollection']),
    showEditOfSharing: {
      get() {
        return this.$store.state.Collections.showEditOfSharing;
      },
      set(val) {
        this.setShowEditOfSharing(val);
      },
    },
    hasAnyFreeUsersInAutocomplete() {
      const { createPermission, inviteUsers } = this.changedUsers;
      return [createPermission, inviteUsers].flat().some(user => !user?.paymentStatus) && this.permission_type != TYPE_VIEWER;
    },
    customMembersDialog: {
      get() {
        return !!this.invitedUsersForShareWorkspace.length;
      },
      set() {
        this.$store.commit('Workspace/setInvitedUsersForShareWorkspace', []);
      },
    },
    errorLimitMessage() {
      if (this.dialogType !== TYPE_WORKSPACE_SHARE) {
        return '';
      }
      const {
        usedLicenses,
        premiumLicenses,
        freeLicenses,
        workspaceType,
      } = this.getActiveWorkspace || {
      };
      const licenses = workspaceType === 'free'
        ? freeLicenses : premiumLicenses;
      const selectedUsersLength = this.addToWorkspaceMembers?.length || 0;
      const extraUsers = licenses - usedLicenses - selectedUsersLength;
      if (extraUsers < 0) {
        return ErrorsText.SHARE_LIMIT_ERROR(extraUsers, usedLicenses, licenses);
      }
      return '';
    },
    filteredMembers() {
      return this.members ? this.members.filter(member => member) : [];
    },
    filteredPermissions() {
      return this.permissions.filter(({ id, email }) => id && email);
    },
    selectErrors() {
      const limitOfSharedUsers = 5; // because we don't count owner
      const limit = limitOfSharedUsers - (this.filteredPermissions.length + this.changedUsers.createPermission.length + this.changedUsers.inviteUsers.length);
      if (this.canWorkWithShareLimit && limit < 0) {
        return ErrorsText.USERS_LIMIT_ERROR(limit, limitOfSharedUsers);
      }
      return '';
    },
    validationEmailErrors() {
      const changedUsers = [this.changedUsers.inviteUsers];
      if (!changedUsers.length) {
        return [];
      }
      return changedUsers.flatMap(users => users.filter(user => !isValidEmail(user)));
    },
    getEmitCriteriaForWorkspace() {
      let emitCriteria = 'manageSharedUser';
      let args = '';
      if (this.dialogType == TYPE_WORKSPACE_SHARE) {
        args = {
          type: this.permission_type,
          ids: this.addToWorkspaceMembers.map(member => member.id),
        };
        if (this.saveType == 'update') {
          if (this.changedUsers.updatePermission.length) {
            args = this.changedUsers.updatePermission.map(({ id, type }) => (
              {
                id, type,
              }));
            emitCriteria = 'updateSharedUser';
          } else {
            args = this.changedUsers.deletePermission.map(({ id }) => ({
              id,
            }));
            emitCriteria = 'deleteSharedUser';
          }
        }
        return [args, emitCriteria];
      }
      return null;
    },
    guestLimitReached() {
      if (!this.corePackageUpdates) return false;
      return this.hasHitGuestLimit;
    },
  },
  watch: {
    members: {
      handler() {
        this.updatePermissions();
      },
      deep: true,
    },
    permissions: {
      handler() {
        this.intersections = this.lodash.differenceWith(this.clonedPermissions,
          this.permissions, this.lodash.isEqual);
        if (this.intersections.length) {
          this.isChanged = true;
        } else {
          this.isChanged = false;
        }
      },
      deep: true,
    },
    showEditOfSharing() {
      if (!this.showEditOfSharing) {
        this.clearShareForm();
      }
    },
    statusDialog: {
      handler(val) {
        if (!val) {
          this.clearChangedUsers();
          this.clearShareForm();
          if (this.dialogType == TYPE_WORKSPACE_SHARE) {
            this.clearAddToWorkspaceMembers();
          }
        } else {
          this.updatePermissions();
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions(['getAvatar', 'handleError']),
    ...mapMutations('Collections', ['setPrivateCollectionsList', 'setShowEditOfSharing']),
    ...mapMutations({
      spinner: 'spinner',
      openSnackBar: 'openSnackBar',
    }),
    ...mapActions('Workspace', ['listUsersInWorkspace', 'updateWorkspaceMemberships']),

    getFullLinkForWorkspaces,

    checkIfMemberOrGuest(users, user) {
      return users.find(u => {
        const email = u?.userInfo?.email ?? u?.email;
        if (isObject(user)) {
          return user.email == email;
        }
        return email == user;
      });
    },
    isCurrentUser(userId) {
      return this.userId === userId;
    },
    async checkSaveChanges(saveType = 'create') {
      if (this.validationEmailErrors.length) {
        this.showValidationEmailErrors = true;
        return;
      }
      this.showValidationEmailErrors = false;
      if (this.dialogType !== TYPE_WORKSPACE_SHARE && this.hasAnyFreeUsersInAutocomplete) {
        let confirmToProceed = await this.$openConfirm({
          text: CONFIRM_TO_PROCEED_IF_FREE_USERS,
        });
        if (!confirmToProceed) {
          return;
        }
      }
      if (this.dialogType == TYPE_WORKSPACE_SHARE && saveType == 'update') {
        const response = await this.$openConfirm({
          text: 'Are you sure ?',
        });
        if (!response) {
          return;
        }
      }
      this.saveType = saveType;
      this.saveChanges(this.dialogType !== TYPE_WORKSPACE_SHARE);
      if (this.showWorkspaceAddMemberModal) {
        await this.listUsersInWorkspace({
          type: TYPE_MEMBER,
          useSpinner: false,
        });
      }
      if (this.dialogType == TYPE_WORKSPACE_SHARE) {
        this.manageWorkspaceUsersPermissionByOne();
        return;
      }
      this.managePermission();
    },
    async manageWorkspaceUsersPermissionByOne() {
      const { guests = [], members = [], other = [] } = this.addedWsUsers;
      await Promise.all(guests.map(async user => {
        const confirmToProceed = await this.$openConfirm({
          text: `Invite ${user?.email} Guest as a Workspace Member? This will give the User access to ALL OBJECTS in this Workspace with their assigned role.`,
        });
        if (!confirmToProceed) {
          return;
        }
        this.updateWorkspaceMemberships([user]);
      }));
      await Promise.all(members.map(user => {
        this.openSnackBar({
          title: `User ${user?.email ?? user} is already invited.`,
        });
      }));
      this.manageWorkspaceUserPermission(other);
    },
    updateType(id, type) {
      this.permissions = this.permissions.map(permission => ({
        ...permission,
        directGrant: {
          ...permission?.directGrant,
          type: permission.id === id ? type : permission?.directGrant?.type,
        },
        type: permission.id === id ? type : permission.type,
      }));
    },
    clearChangedUsers() {
      this.changedUsers.updatePermission = [];
      this.changedUsers.deletePermission = [];
    },
    clearAddToWorkspaceMembers() {
      this.addToWorkspaceMembers = [];
    },
    saveChanges(useSpinner = true) {
      if (useSpinner) {
        this.spinner(true);
      }
      this.clearChangedUsers();
      this.intersections.forEach((item) => {
        const deletedIndex = this.lodash.findIndex(this.permissions,
          user => user.id === (item?.userInfo?.id || item?.id));
        if (deletedIndex !== -1) {
          if (item.update) {
            this.changedUsers.updatePermission.push({
              id: this.permissions[deletedIndex].id,
              type: this.permission_type,
              email: this.permissions[deletedIndex].email,
            });
          } else {
            this.changedUsers.updatePermission.push(this.permissions[deletedIndex]);
          }
        } else {
          this.changedUsers.deletePermission.push(item);
        }
      });
    },
    selectedUsers(selected) {
      if (this.dialogType == TYPE_WORKSPACE_SHARE) {
        this.addToWorkspaceMembers = selected;
      }
      const createPermission = [];
      const inviteUsers = [];
      this.intersections = this.intersections.filter(item => !item.update);
      selected.forEach(((item) => {
        if (typeof item === 'object') {
          const findUserIndex = this.lodash.findIndex(this.permissions, user => user.id === item.id);
          if (findUserIndex >= 0) {
            const findUser = this.lodash.findIndex(this.intersections, user => user.id === item.id);
            if (findUser >= 0) {
              this.intersections[findUser] = {
                userInfo: item,
                update: true,
              };
            } else {
              this.intersections[this.intersections.length] = {
                userInfo: item,
                update: true,
              };
            }
          } else {
            createPermission.push(item);
          }
        } else {
          inviteUsers.push(item);
        }
      }));
      this.changedUsers = {
        ...this.changedUsers,
        inviteUsers,
        createPermission,
        updatePermission: [],
      };
      this.showEditOfSharing = Boolean(selected.length);
    },
    async deletePermission(id) {
      const deletedIndex = this.lodash.findIndex(this.permissions,
        user => user.id === id);
      this.permissions.splice(deletedIndex, 1);
    },
    updatePermissions() {
      const { filteredMembers } = this;
      this.permissions = this.lodash.cloneDeep(filteredMembers || []);
      this.permissions = this.lodash.orderBy(this.permissions, ['type'], ['asc']);
      this.clonedPermissions = this.lodash.cloneDeep(this.permissions);
      this.permissions.forEach(async (item) => {
        if (item.picture && !this.avatars[item.picture]) {
          this.getAvatar({
            key: item.picture,
            getAvatars: true,
            context: this,
          });
        }
      });
      return;
    },
    async managePermission() {
      const changedUsers = await this.getCorrectedChangedUsers();
      await Promise.all(Object.keys(changedUsers).map((item) => {
        changedUsers[item].map((user) => {
          if (item === 'createPermission') {
            this.manageSharedUserInCollection({
              sharingUserId: user?.userInfo?.id ?? user.id,
              permissionType: this.permission_type,
              email: user?.userInfo?.email ?? user.email,
              emailMessage: this.shareMessage,
            }, item);
          } else if (item === 'deletePermission') {
            this.manageSharedUserInCollection({
              targetUserId: user.userInfo ? user.userInfo.id : user.id,
            }, item);
          } else if (item === 'inviteUsers') {
            const updatedUser = this.permissions.find(({ email }) => email === user);
            if (updatedUser) {
              this.manageSharedUserInCollection({
                permissionType: this.permission_type,
                targetUserId: updatedUser.id,
                email: user,
              }, 'updatePermission');
            } else {
              this.manageSharedUserInCollection({
                permissionType: this.permission_type,
                email: user,
                emailMessage: this.shareMessage,
              }, item);
            }
          } else {
            this.manageSharedUserInCollection({
              targetUserId: user.userInfo ? user.userInfo.id : user.id,
              permissionType: this.getRoleByPermission(user),
              email: user.email,
            }, item);
          }
        });
      }));
      this.shareMessage = '';
      this.$emit('closeModal');
    },
    async getCorrectedChangedUsers() {
      // The autocomplete tries to get userData for each email, but is slow.
      // If you send fast, it sends an 'invite' to an existing user - which will not work correctly.
      // - We try to get all required user info here (help hides latency vs checking in autocomplete).
      const correctedInvite = [];
      const correctedCreate = [];
      for (const email of this.changedUsers.inviteUsers) {
        const userData = await this.getUserData(email);
        if (userData) {
          correctedCreate.push(userData);
        } else {
          correctedInvite.push(email);
        }
      }
      for (const originalUser of this.changedUsers.createPermission) {
        if (!correctedCreate.some((user) => user.id === originalUser.id)) {
          correctedCreate.push(originalUser);
        }
      }
      return {
        ...this.changedUsers,
        inviteUsers: correctedInvite,
        createPermission: correctedCreate,
      };
    },
    async getUserData(email) {
      const { data } = await userProfileAPI.getUserProfileByEmail({
        email: email,
      });
      if (data.response.length) {
        return data.response[0];
      }
      return null;
    },
    manageWorkspaceUserPermission(membersToShare) {
      let emitCriteria = 'manageSharedUser';
      let args = {
        members: membersToShare.map((member) => {
          if (typeof member == 'string') {
            return {
              email: member,
              role: this.permission_type,
            };
          }
          return {
            id: member.id,
            email: member.email,
            role: this.permission_type,
          };
        }),
        emailMessage: this.shareMessage,
      };
      if (this.saveType == 'update') {
        if (this.changedUsers.updatePermission.length) {
          args = this.changedUsers.updatePermission.map(({ id, type }) => (
            {
              id, type,
            }));
          emitCriteria = 'updateSharedUser';
        } else {
          args = this.changedUsers.deletePermission.map(({ id }) => ({
            id,
          }));
          emitCriteria = 'deleteSharedUser';
        }
      }
      const { members = [] } = args ?? {
      };
      const trimMemberEmail = members?.map(e => ({
        ...e, email: e.email.trim(),
      })) || [];
      this.$set(args, 'members', trimMemberEmail);
      this.$emit(emitCriteria, args);
      this.$emit('closeModal');
      this.emailMessage = '';
    },
    manageSharedUserInCollection(variables, criteria) {
      if (variables.email) this.$set(variables, 'email', variables.email.trim());
      let args = {
        variables,
        criteria,
      };
      this.$emit('manageSharedUser', args);
    },
    clearShareForm() {
      this.$refs.shareForm?.reset();
      this.permission_type = 'viewer';
      this.shareMessage = '';
    },
    pushToPayment() {
      this.$router.push({
        name: 'payment',
      });
    },
  },
};
</script>
<style scoped lang="scss">
.creator-dot-container {
  display: flex;
  align-items: center;
  &::after {
    content: '';
    height: 5px;
    width: 5px;
    border-radius: 50%;
    display: flex;
    margin-left: 10px;
    background: var(--v-blue-base);
  }
}
.error--text {
  font-size: 12px;
  b {
    font-size: 16px;
    cursor: pointer;
  }
}
::v-deep .v-label {
  color: var(--v-lightBlack-base) !important;
}
.small-p {
  padding: 0 !important;
}
</style>
