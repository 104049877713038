<template>
  <div
    :class="{
      'secondary-space': showAIChat,
    }"
    class="app-add-button-no-menu z-99">
    <InteractiveTooltip
      :color="colorTooltip"
      content-class="btn-tooltip"
      top>
      <template v-slot:activator="{ on }">
        <button
          :class="[
            type == 'footer' ? 'add-icon-container' : 'add-icon-container__relative',
            { 'disabled': disabled }
          ]"
          :disabled="isDisabled"
          class="blue"
          v-on="isDisabled ? on : ''"
          @click="clickedEvent">
          <v-icon
            :color="type == 'footer' ? 'white' : ''"
            :size="$vuetify.breakpoint.mdAndUp ? 30 : 20">
            mdi-plus
          </v-icon>
        </button>
      </template>
      <span>
        {{ reasonText }}
        <router-link
          :v-if="reasonLink"
          :to="reasonLink">here
        </router-link>
      </span>
    </InteractiveTooltip>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import appBtnMixin from '@/mixins/appBtnMixin';
import InteractiveTooltip from '@/components/App/AppInteractiveTooltip';

export default {
  name: 'AppAddButtonNoMenu',
  components: {
    InteractiveTooltip,
  },
  mixins: [appBtnMixin],
  props: {
    type: {
      type: String,
      default: 'footer',
      validator(value) {
        return ['footer', 'basic'].includes(value);
      },
    },
    clickCallback: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters('FeatureFlags', ['showAIChat']),
  },
  methods: {
    clickedEvent() {
      if (!this.disabled) this.clickCallback();
    },
  },
};
</script>
