var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app-add-button-no-menu z-99",class:{
    'secondary-space': _vm.showAIChat,
  }},[_c('InteractiveTooltip',{attrs:{"color":_vm.colorTooltip,"content-class":"btn-tooltip","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
return [_c('button',_vm._g({staticClass:"blue",class:[
          _vm.type == 'footer' ? 'add-icon-container' : 'add-icon-container__relative',
          { 'disabled': _vm.disabled }
        ],attrs:{"disabled":_vm.isDisabled},on:{"click":_vm.clickedEvent}},_vm.isDisabled ? on : ''),[_c('v-icon',{attrs:{"color":_vm.type == 'footer' ? 'white' : '',"size":_vm.$vuetify.breakpoint.mdAndUp ? 30 : 20}},[_vm._v(" mdi-plus ")])],1)]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.reasonText)+" "),_c('router-link',{attrs:{"v-if":_vm.reasonLink,"to":_vm.reasonLink}},[_vm._v("here ")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }